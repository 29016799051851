const paid_or_not_icons = () => {
  const allIconsLinks = document.querySelectorAll("#paid-or-not-icon");
  Array.from(allIconsLinks).forEach((icon) => {
    icon.addEventListener("click", function (event) {
      const threeIcons = event.target.closest("#paid-or-not-links").querySelectorAll("#paid-or-not-icon");
      Array.from(threeIcons).forEach((icon) => {
        if (icon === event.target) {
          if (icon.classList.contains('inactive')) {
            icon.classList.replace('inactive', 'active');
          }
        } else {
          icon.classList.replace('active', 'inactive')
        }
      });
    });
  });
};

export { paid_or_not_icons };
